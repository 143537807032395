import { useRef, useEffect } from "react";
import React from "react";
import "../index.css";
import '../webfonts/Bogart-Bold.ttf';
import { Link } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Typewriter from "typewriter-effect";
import AnimatedCursor from "react-animated-cursor";

// importing assets

import image1 from '../assets/pic01.jpg'; 
import image2 from '../assets/pic02.jpg'; 
import image3 from '../assets/pic03.jpg'; 
import image4 from '../assets/pic04.jpg'; 
import image5 from '../assets/pic05.jpg'; 
import image6 from '../assets/pic06.jpg'; 
import image7 from '../assets/pic07.jpg'; 
import image8 from '../assets/pic08.jpg'; 
import image9 from '../assets/pic09.jpg';
import junilogo from '../assets/juni_logo_white.png'
import papermill_logo from '../assets/PAPERMILL_LOGO.png'
import papermillBg from '../assets/papermill_bg.png'
import sotslogo from '../assets/SOTSlogo.png'
import fallingMan from '../assets/fallingmanlogo.png'
import destoreLogo from '../assets/destorelogonew.png'

//  hide and show small chat window

    function showHideToggleBody() {
  var chat = document.getElementById("chatbot");
  var turnOn = document.getElementById("turnOn");
  var turnOff = document.getElementById("turnOff");
  var pagewrap = document.getElementById("page-wrap")

  if (chat.style.maxWidth === "0%") {
    console.log("")
  } else {
    chat.style.maxWidth = "0%";
    turnOn.style.visibility = "hidden";
    turnOff.style.visibility = "visible";
  }
}

// simple scroll to top function

    function handleScrollTop() {
    window.scroll({
      top: 0,
      left: 0, 
      behavior: 'smooth',
    });
  }

export default function Hero(){

    /* GSAP STUFF */
  gsap.registerPlugin(ScrollTrigger)

// declaring ref elements for gsap animations
    const header1 = useRef();
    const headerSub = useRef();
    const tiles = useRef();
    const aboutMe = useRef();
    const aboutMePara = useRef();
    const item1 = useRef();
    const item2 = useRef();
    const item3 = useRef();
    const item4 = useRef();
    const item5 = useRef();
    const item6 = useRef();
    const item7 = useRef();
    const item8 = useRef();
    const item9 = useRef();
    const emailBtn = useRef();


// various animations (in rough order of appearance)


useEffect(() => {
  gsap.from((header1.current), {
    y: "-50px",
     duration: 1,
     ease:Power3.easeInOut,
     opacity: 0
     
    });
}, );

useEffect(() => {
  gsap.from((headerSub.current), {
    y: "50px",
    duration: 1,
    ease:Power3.easeInOut,
    opacity: 0,
    delay: 0.4
  });
})

useEffect(() => {
  gsap.from((tiles.current), {
    y: "50px",
    duration: 1,
    ease:Power3.easeInOut,
    opacity: 0,
    delay: 1
  });
})


useEffect(() => {
  gsap.from((aboutMe.current), {
       scrollTrigger: {
        trigger: aboutMe.current,
        toggleActions: "play none none none"
       },
      opacity: 0,
      duration: 0.5
      });
  });

  useEffect(() => {
  gsap.from((aboutMePara.current), {
       scrollTrigger: {
        trigger: aboutMePara.current,
        toggleActions: "play none none none"
       },
      opacity: 0,
      duration: 0.5
      });
  });

function copyText() {
  /* Get the text field */
  var copyText = document.getElementById("myInput");

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

   /* Copy the text inside the text field */
  navigator.clipboard.writeText(copyText.value);

  /* Alert the copied text */
  alert("Copied the text: " + copyText.value);
} 





// JSX begins

    return(

<div onClick={showHideToggleBody} id="page-wrap">
  <div id="wrapper">

    {/* Header */}
    <header id="header">
      <div className="inner">
        {/* Logo */}
        <a href="index.html" className="logo">
      
        </a>
      </div>
    </header>

    <div id="main">
      <title >Julian Osborn</title>
      <div className="inner">
        <header id="mainHeader">
          <h1 ref={header1} style={{ fontFamily: "Bogart Regular" }}>
            This is my website. <br/>

          </h1>
        </header>
        <section className="tiles" id="Work" ref={tiles}>


        </section>
      </div>
    </div>
    <div id="main">
      <div className="inner" id="About">
        <header>
          <p id="about1" ref={aboutMePara}>I'm currently <Typewriter
            options={{
    strings: [ 'based in Fremantle, WA.',  '22 years old.'],
    delay: 60,
    deleteSpeed: 20,
    pauseFor: 6000,
    autoStart: true,
    loop: true,}}
/> 
           <br /> I'm interested in <Typewriter
            options={{
    strings: ['painting.', 'web design.', 'illustration.', 'crate-digging.', 'reading books.', 'music.', 'programming.', `graphic novels.`, `animation.`,  `podcasts.`, `cassettes.`, `chess.`, `print-making.`, ],
    delay: 60,
    deleteSpeed: 20,
    pauseFor: 2000,
    autoStart: true,
    loop: true,}}
/> 
           
            <br />I have experience with <Typewriter
            options={{
    strings: [ `Javascript.`, `Adobe Photoshop.`, `Figma.`, `Wordpress.`, `Shopify.`, `HTML & CSS.`, `selling goods.`, `UX Design.`, `WebRTC.`, `TailwindCSS.`, `Firebase.`, `version control.`, `Adobe Indesign.`, `React.`, `Adobe XD.`, `Docker.`, `logo development.`, `WSL2.`, `iterative design.`, `Craft CMS.`, `domain management.`, `SCSS.`, `APIs.`, `Adobe Illustrator.`, `SEO.`, `NextJS.`, `accessibility design.`, `responsive design.`, `Adobe Premiere.`, `design systems.`, `UI Design.`, `collaboration.`, `website deployment.`, `Node.JS.`, `mongoDB.`, `Norman's principles.`, `Git.`, `Gestalt theory.`],
    delay: 60,
    deleteSpeed: 20,
    pauseFor: 2000,
    autoStart: true,
    loop: true,}}
/> 
           </p>
        </header>
      </div>
    </div>
    {/* Footer */}
    
    <footer id="footer">
      <div className="inner" id="Contact">
        <section>
         
          <p>
            I'm currently working on an independent project.
            <br />
            However, if you have a question, please email me. <br />
            👉 support@yourbestfriendjippy.com
          </p>


        </section>
        <section id="atSymbolSection">
          <h2 style={{ fontFamily: "Bogart Regular" }}>WHERE I'M <span id="atSymbol">@</span></h2>
          <ul className="icons">
            <li>
              <a
                href="https://www.instagram.com/yourbestfriendjippy/"
                className="icon brands style2">
                <i class="fa fa-instagram" aria-hidden="true"></i>
                <span className="label">
                Instagram
                </span>
              </a>
            </li>
             <li>
              <a
                href="https://jippy.bandcamp.com"
                className="icon solid style2">
                         <i class="fa fa-bandcamp " aria-hidden="true"></i>
                <span className="label">Bandcamp</span>
              </a>
            </li>
          </ul>
        </section>
        <ul className="copyright">
          <li>I C U ;)</li>
        </ul>
      </div>
    </footer>
  </div>
</div>
    )
}

