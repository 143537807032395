import ChatBot from 'react-simple-chatbot';
import React from 'react';
import Hero from './Hero';
import "./chatbot.css";
import { ThemeProvider } from 'styled-components';
import 'font-awesome/css/font-awesome.min.css';
import { useRef, useEffect } from "react";
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const theme = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica Neue',
  headerBgColor: '#222222',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#ffffff',
  botFontColor: '#222222',
  userBubbleColor: '#fff6eb',
  userFontColor: '#222222',
  
};

    function showHideToggle() {
  var chat = document.getElementById("chatbot");
  var turnOn = document.getElementById("turnOn");
  var turnOff = document.getElementById("turnOff");

  if (chat.style.maxWidth = "0%") {
    chat.style.maxWidth = "360px";
    turnOn.style.visibility = "visible";
    turnOff.style.visibility = "hidden";
  } else {
    chat.style.maxWidth = "0%";
    turnOn.style.visibility = "hidden";
    turnOff.style.visibility = "visible";
  }
}

function hideToggle(){
  var chat = document.getElementById("chatbot");
  var turnOn = document.getElementById("turnOn");
  var turnOff = document.getElementById("turnOff");

    if (chat.style.maxWidth = "0%") {
    chat.style.maxWidth = "360px";
    turnOn.style.visibility = "visible";
    turnOff.style.visibility = "hidden";
  } else {
    chat.style.maxWidth = "0%";
    turnOn.style.visibility = "hidden";
    turnOff.style.visibility = "visible";
  }
}


function Chatbot() {

      /* GSAP STUFF */
  gsap.registerPlugin(ScrollTrigger)

// declaring ref elements for gsap animations
    const turnOn = useRef();



// various animations (in rough order of appearance)


useEffect(() => {
  gsap.from((turnOn.current), {
    x: "100px",
     duration: 1,
     ease:Power3.easeInOut,
     delay: 1.5
    });
}, );
  
    return(

      <div>
             <div ref={turnOn} onClick={hideToggle} id="turnOn"><button><i class="fa fa-comment" aria-hidden="true"></i></button></div>
             <div onClick={showHideToggle} id="turnOff"><button><i class="fa fa-comment" aria-hidden="true"></i></button></div>
        <div className="chatbot" id="chatbot">
            <ThemeProvider theme={theme}>
   <ChatBot
    steps={[
      {
        id: '1',
        message: `Hi there. I'm the robot version of Julian.`,
        trigger: '2',
      },
         {
        id: '2',
        message: `How can I help?`,
        trigger: '3',
      },
      {
        id: '3',
        options: [
          { value: 1, label: '🎨 Tell me more about yourself.', trigger: '6' },
          { value: 2, label: `💼 What services do you offer?`, trigger: '5' },
          { value: 3, label: '👀 Just looking for now.', trigger: '4' },
        ],
      },
      {
        id: '4',
        message: 'Thanks for stopping by!',
        trigger: '11',
      },
      {
        id: '5',
        message: 'Web-design & graphic design',
        trigger: '2',
      },
            {
        id: '6',
        message: `What would you like to know?`,
        trigger: '7',
      },
      {
              id: '7',
        message: `Choose wisely. `,
        trigger: '9',
      },
                  {
        id: '9',
        message: `:)`,
        trigger: '10',
      },
            {
        id: '10',
        options: [
          { value: 1, label: `🎉 What do you do for fun?`, trigger: '13' },
          { value: 2, label: `🎨 Who are your favourite artists?`, trigger: '5' },
          { value: 3, label: `💻 What do you like about web design?`, trigger: '6' },
        ],
      },
      {
              id: '11',
        message: `What do you think about my portfolio?`,
        trigger: '12',
      },
            {
        id: '12',
        options: [
          { value: 1, label: `It's fantastic!`, trigger: '4' },
          { value: 2, label: `It's alright.`, trigger: '5' },
          { value: 3, label: `It's... okay.`, trigger: '6' },
        ],
      },
            {
              id: '13',
        message: `My favourite thing to do is listen to music.`,
        trigger: '14',
      },
                  {
              id: '14',
        message: `I especially like instrumental hiphop.`,
        trigger: '15',
      },
                        {
              id: '15',
        message: `I also produce sampled music from old vinyl records.`,
        trigger: '16',
      },
                  {
        id: '16',
        options: [
          { value: 1, label: `🎉 Your favourite genre?`, trigger: '17' },
          { value: 2, label: `🎨 Your top five albums?`, trigger: '17' },
          { value: 3, label: `💻 What else do you like to do?`, trigger: '6' },
        ],
      },
      {
                    id: '17',
        message: `Hmmmm... That's a tough one.`,
        trigger: '18',
      },
            {
                    id: '18',
        message: `I'd say, #5 Busta Rhymes - The Coming `,
        trigger: '19',
      },
                  {
                    id: '19',
        message: `#4 A Tribe Called Quest - Midnight Marauders`,
        trigger: '20',
      },
                       {
                    id: '20',
        message: `#3 Quasimoto - The Unseen`,
        trigger: '21',
      },
      {
                    id: '21',
        message: `#2 Jaylib - Champion Sound.`,
        trigger: '22',
      },
      {
                          id: '21',
        message: `#1 Madvillain - Madvillainy.`,
        trigger: '6',
      },
      
      

      
    ]}
  />



  </ThemeProvider>

</div>
<div>
  
</div>

</div>




)

}

export default Chatbot