import { stack as Menu } from 'react-burger-menu'

import React from 'react';
import Hero from './Hero';
import Chatbot from './Chatbot';
import "../index.css";
import '../webfonts/Bogart-Bold.ttf';
import { useRef, useEffect } from "react";
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from 'react-router-dom';

import burgerIcon from '../assets/hamburger_icon.svg';
import AnimatedCursor from 'react-animated-cursor';

  function handleScroll() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior: 'smooth',
    });
  }

    function handleScrollTop() {
    window.scroll({
      top: 0,
      left: 0, 
      behavior: 'smooth',
    });
  }

        function handleScrollAbout() {
    window.scroll({
      top: 1600,
      left: 0, 
      behavior: 'smooth',
    });

        }

      function handleScrollWork() {
    window.scroll({
      top: 500,
      left: 0, 
      behavior: 'smooth',
    });


    
  }


export default function Hamburger(){

    const menu = useRef();


// various animations (in rough order of appearance)




    return ( <div id="outer-container">
      <Chatbot></Chatbot>
    
  <Menu customBurgerIcon={ <img src={ burgerIcon } /> } width="360px" pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } >
     <a id="home" onClick={handleScrollTop} className="menu-item relative" href="https://www.youtube.com/channel/UC17Plu6pe6IJVXUttD2uIWA">Youtube</a>
      <a id="home" onClick={handleScrollTop} className="menu-item relative" href="https://open.spotify.com/artist/2nyEmQWHkN4klNBWnNllLZ">Spotify</a>
       <a id="home" onClick={handleScrollTop} className="menu-item relative" href="https://jippy.bandcamp.com/">Bandcamp</a>
        <a id="home" onClick={handleScrollTop} className="menu-item relative" href="https://yourbestfriendjippy.com/">YBFJ.com</a>
         <a id="home" onClick={handleScrollTop} className="menu-item relative" href="https://www.instagram.com/yourbestfriendjippy/">Instagram</a>



  </Menu>
  
  <main id="page-wrap">
    
         <Hero></Hero>
    
  </main>
    
</div>
    
    );
  }




