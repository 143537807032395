import React from "react"

import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import Chatbot from '../components/Chatbot'
import Avatar from "./Avatar"
import Hamburger from "./Hamburger"
import Juni from "./Juni"
import Hero from "./Hero"
import Showreel from "./Showreel"
import AnimatedCursor from "react-animated-cursor"
import Soundsofthestudio from "./Soundsofthestudio"
import Papermill from "./Papermill"
import Jippy from "./Jippy"
import DeStore from './DeStore'


function App() {
  return (
    
    <div style={{ fontFamily: 'Avenir' }}>
                  <AnimatedCursor
          innerSize={10}
      outerSize={6}
      color='50, 50, 50'
      outerAlpha={0.2}
      innerScale={1}
      outerScale={6}
       />
      <Router>
  
        {/* <AuthProvider> */}    
          <Switch>
        
             <Route path="/home" component={Hero}/>  
         <Hamburger right></Hamburger>
            
            
            {/* <Route path="/chats" component={Chats} />
            <Route path="/" component={Login} /> */}
          </Switch>
        {/* </AuthProvider> */}
      </Router>
    </div>
  )
}

export default App
